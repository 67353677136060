/// <reference types="@angular/localize" />

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { hmrBootstrap } from './hmr';
import * as Sentry from '@sentry/angular';
import { TenantSettingService } from './app/core/services/tenantSetting/tenant-setting.service';
import { firstValueFrom } from 'rxjs';

if (environment.production || true) {
	enableProdMode();
	platformBrowserDynamic()
		.bootstrapModule(AppModule)
		.then(async (module) => {
			const tenantSettingService = module.injector.get(TenantSettingService);
			const tenantSetting = await firstValueFrom(tenantSettingService.getTenantSetting().valueChanges);
			const tenantId = tenantSetting.data.tenantSetting.tenantId;

			Sentry.init({
				dsn: 'https://2ffd419c2238f5717e1ea253d74676a9@o66931.ingest.us.sentry.io/4507004510863360',
				integrations: [],
			});

			Sentry.setTag('tenantId', tenantId);
		});
}

const bootstrap = () => platformBrowserDynamic().bootstrapModule(AppModule);
if (environment.hmr) {
	if (module['hot']) {
		hmrBootstrap(module, bootstrap);
	} else {
		console.error('HMR is not enabled for webpack-dev-server!');
		console.log('Are you using the --hmr flag for ng serve?');
	}
} else {
	bootstrap().catch((err) => console.log(err));
}
